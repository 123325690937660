import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";
import { IUser } from "@shared/interfaces";

export const UserListPermissionsTableConst = [
  "user.name",
  "user.contactEmail",
  "title",
  "subRoleName",
  "action",
  "selection",
];

export const UserListPermissionsTableLabelConst = [
  "Active Member",
  "Email",
  "Title",
  "Role",
  "Action",
  "",
];

export const UserListPermissionsTableSortConst = ["name"];

export const ManageRolePermissionsTableConst = [
  "name",
  "usersCount",
  "selection",
];

export const ManageRolePermissionsTableLabelConst = [
  "Roles",
  "User With Role",
  "",
];

export const ManageRolePermissionsTableSortConst = ["name"];

export const confirmDelete: IConfirmationDialog = {
  title: "Delete User",
  body: `
      <div class="fw-400 pt-8">Are you sure want to delete this user?</div>`,
  btnCancel: "Cancel",
  btnSubmit: "Okay",
};
export const confirmDeleteRole: IConfirmationDialog = {
  title: "Delete Role",
  body: `
      <div class="fw-400 pt-8">Are you sure want to delete this role?</div>`,
  btnCancel: "Cancel",
  btnSubmit: "Okay",
};

export const confirmExit: IConfirmationDialog = {
  title: "Going Somewhere?",
  body: `
      <div class="fw-400 pt-8">You will lose any unsaved changes.</div>`,
  btnCancel: "Keep Editing",
  btnSubmit: "Continue",
};

export interface IUserList {
  roleId: string;
  subRoleId?: string;
  subRoleName: string;
  title: string;
  user: IUser;
  userId: string;
}

export interface IRole {
  id: string;
  name: string;
  userCount: number;
  modules: IModules;
  status: number;
  user?: IUser;
}

export interface IModules {
  [key: string]: IRules | ISubModules;
}
export interface ISubModules {
  [key: string]: IRules;
}

export interface IRules {
  read: boolean;
  write: boolean;
  void: boolean;
}

export namespace NMember {
  export interface IMember {
    id: string;
    name: string;
    address: string;
    imageUrl?: string;
    checked: boolean;
  }
}
