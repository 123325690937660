import { booleanAttribute, Component, EventEmitter, input, Input, Output } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RoamButtonComponent } from "../button/roam-button/roam-button.component";
import { MatDialogModule } from "@angular/material/dialog";

@Component({
  standalone: true,
  selector: "app-container-dialog-set",
  templateUrl: "./container-dialog-set.component.html",
  styleUrls: ["./container-dialog-set.component.scss"],
  imports: [MatProgressSpinnerModule, RoamButtonComponent, MatDialogModule]
})
export class ContainerDialogSetComponent {
  @Input()
  public isLoading!: boolean;
  readonly isValid = input(false, { transform:booleanAttribute });
  readonly buttonLabel = input("Save");
  readonly buttonLabelSaveAndContinue = input("Save & Continue");
  readonly hideButton = input<boolean>(false);

  @Output()
  public onSave: EventEmitter<string> = new EventEmitter();
  @Output()
  public onClose: EventEmitter<Event> = new EventEmitter<Event>();
}
